import styled from 'styled-components'

import { roletaBG } from '../../../images/totem/index'

export const AsicsbackTotem = styled.div`
  background-color: #c4ddf2;
  min-height: 102vh;
  width: 100%;
  padding-bottom: 30px;
  margin-top: -80px;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;

  .roleta {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;

    .roletaHolder {
      display: flex;
      justify-content: center;
      flex-flow: column;
      position: relative;
      background-image: url(${roletaBG});
      background-position: center top;
      background-repeat: no-repeat;
      background-size: cover;
      width: 705px;
      height: 705px;

      @media (max-width: 768px) {
        width: 480px;
        height: 480px;
      }
      @media (max-width: 480px) {
        width: 360px;
        height: 360px;
      }

      canvas {
        width: 637px;
        height: 637px;
        margin: 0 auto;

        @media (max-width: 768px) {
          width: 430px;
          height: 430px;
        }
        @media (max-width: 480px) {
          width: 320px;
          height: 320px;
        }
      }

      .pointer {
        position: absolute;
        top: -80px;
        left: 50%;
        transform: translateX(-50%);
        @media (max-width: 768px) {
          top: -28px;
          left: 50%;
          transform: translateX(-50%);
          width: 15%; 
        }
      }

      .centerPointer {
        background:#FFF;
        width:147px;
        height:147px;
        position:absolute;
        top:50%;
        left:50%;
        border-radius:50%;
        transform: translate(-50%, -50%);
        filter: drop-shadow(2px 4px 6px #333);
        @media (max-width: 768px){
          width: 100px;
          height: 100px;
        }
      }
    }

    button {
      display: flex;
      padding: 16.863px 26.981px 12px;
      justify-content: center;
      align-items: center;
      border-radius: 67.453px;
      background: #001E62;
      color: #FFF;
      text-align: center;
      font-family: 'ASICSFont3.0-Regular';
      font-size: 43.17px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;

      &:disabled {
        opacity:.6;
      }
      @media (max-width: 768px) {
        font-size: 32px;
      }
    }
  }
`
