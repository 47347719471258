import React from 'react'
import PropTypes from 'prop-types'
import { ResultPage, ResultBox, ResultDescription, ResultText, ResultInfo,  } from './styles'

const ResultadoRoleta = ({ resultado, setResultado }) => {
  function tentarNovamente() {
    setResultado(null); 
  }

  return (
	  <ResultPage className="resultado">
		  <ResultBox>
			  <h2>Parabéns!</h2>
			  <ResultDescription>
				<ResultInfo>Você ganhou</ResultInfo>
				<ResultText>{resultado}</ResultText>
				<ResultInfo className='p-desconto'>de desconto</ResultInfo>
			  </ResultDescription>
		  </ResultBox>
      {/* <button onClick={tentarNovamente}>Começar novamente</button> */}
    </ResultPage>
  );
};

ResultadoRoleta.propTypes = {
  resultado: PropTypes.string.isRequired,
  setResultado: PropTypes.func.isRequired,
};

export default ResultadoRoleta;
