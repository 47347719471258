import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { WheelCircle, Segment, Pointer, SpinButton } from './styles';
import Winwheel from 'winwheel';
import { roletaPointer } from '../../../images/totem/index';
import { ditoTrack } from '../../../helpers/dito';
import { navigate } from 'gatsby';

let TweenMax;
if (typeof window !== "undefined") {
  TweenMax = require("gsap").TweenMax;
  window.TweenMax = TweenMax;
}

const Roleta = ({ descontos, loja, setResultado }) => {
  const [items, setItems] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  let descontosSegmentos = [...descontos, ...descontos, ...descontos];
  let theWheel;

  useEffect(() => {
    if (typeof window !== "undefined") {
      const items = !!localStorage.getItem("sem-cadastro");
      setItems(items);
      if (items === true) {
        navigate(`/totem/${loja.slug}/cadastro`);
      } else {
        ditoTrack('concluiu-roleta');
        localStorage.setItem("concluiu-oferta", true);
      }
    }
  }, [loja.slug]);

  function alertPrize() {
    const winningSegment = theWheel.getIndicatedSegment();
    setTimeout(() => {
      setResultado(winningSegment.text);
    }, 1000);
  }

  useEffect(() => {
    const segmentos = descontosSegmentos.map((valor, index) => ({
      'fillStyle': index % 2 === 0 ? '#485CC7' : '#FFF',
      'textFillStyle': index % 2 === 0 ? '#FFF' : '#485CC7',
      'text': `${valor}%`
    }));

    if (typeof window !== "undefined") {
      theWheel = new Winwheel({
        'numSegments': 6,
        'pointerAngle': 0,
        'textFontSize': 80,
        'textFontFamily': 'Arial',
        'segments': segmentos,
        'animation': {
          'type': 'spinToStop',
          'duration': 5,
          'spins': 8,
          'callbackFinished': 'alertPrize()'
        }
      });
    }
  }, [descontosSegmentos]);

  function rodaRoleta() {
    if (typeof window !== "undefined") {
      window.alertPrize = alertPrize;
      theWheel.startAnimation();
      setIsDisabled(true);
    }
  }

  return (
    <div className="roleta">
      <div className="roletaHolder">
        <img src={roletaPointer} alt="" className="pointer" />
        <canvas id="canvas" width="637" height="637"></canvas>
        <div className="centerPointer"></div>
      </div>
      <button onClick={rodaRoleta} disabled={isDisabled}>Girar roleta</button>
    </div>
  );
};

Roleta.propTypes = {
  descontos: PropTypes.arrayOf(PropTypes.number).isRequired,
  setResultado: PropTypes.func.isRequired,
};

export default Roleta;
