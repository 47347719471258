import React, { useEffect, useState } from 'react'
import * as S from '../roleta/styles'
import { graphql } from 'gatsby'
import Layout from '../../../components/totem/layout'
import PropTypes from 'prop-types'
import { ditoTrack } from '../../../helpers/dito'
import BtnVoltar from '../../../components/totem/Voltar'
import Roleta from '../../../components/totem/Roleta'
import ResultadoRoleta from '../../../components/totem/Roleta/resultado'

const AsicsRoleta = ({ pageContext: { loja, data } }) => {
  const [resultado, setResultado] = useState(null); // Novo estado para armazenar o resultado

  useEffect(() => {
    ditoTrack('acessou-asics-back')
  }, [])

  const descontos = Object.keys(loja)
    .filter(key => key.startsWith('roleta_desconto_'))
    .map(key => loja[key])
    .filter(Boolean); // Remove valores undefined ou null

  // Garantir que sempre haja pelo menos 2 descontos, adicionar valores default se necessário
  while (descontos.length < 2) {
    descontos.push(10); // Valor default, pode ser alterado
  }

  return (
    <Layout loja={loja}>
      <S.AsicsbackTotem>
        {!resultado ? (
          <Roleta descontos={descontos} loja={loja} setResultado={setResultado} />
        ) : (
          <ResultadoRoleta resultado={resultado} setResultado={setResultado} />
        )}
      </S.AsicsbackTotem>
      <BtnVoltar />
    </Layout>
  )
}

AsicsRoleta.propTypes = {
  pageContext: PropTypes.shape({
    loja: PropTypes.shape({
      nome: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
}

export default AsicsRoleta

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
