import styled from 'styled-components';



export const ResultPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 100px;
  gap: 40px;
  button{
    display: flex;
    padding: 16.863px 26.981px 12px;
    justify-content: center;
    align-items: center;
    border-radius: 67.453px;
    background: #001E62;
    color: #FFF;
    text-align: center;
    font-family: 'ASICSFont3.0-Regular';
    font-size: 43.17px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    
    @media (max-width: 768px) {
      font-size: 32px;
    }
  }
`;

export const ResultBox = styled.div`
    border-radius: 22.008px;
    background: #FFF;
    box-shadow: 22.008px 22.008px 22.008px 0px rgba(0, 0, 0, 0.07);
    width: 100%;
    height: 100%;
    padding: 60px 90px 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h2{
      color: #001E62;
      text-align: center;
      font-family: 'ASICSFont3.0-Regular';
      font-size: 109.103px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;

      @media (max-width: 768px) {
        font-size: 80px;
      }
    }
    @media (max-width: 768px) {
      width: 75%;
    }
`;

export const ResultDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .p-desconto{
    margin-top: -40px;
  }
  @media (max-width: 768px) {
    .p-desconto{
      margin-top: -10px;
    }
  }
`;

export const ResultText = styled.p`
    color: #485CC7;
    text-align: center;
    font-family: 'ASICSFont3.0-Regular';
    font-size: 276.417px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    @media (max-width: 768px) {
      font-size: 120px;
    }
`;

export const ResultInfo = styled.p`
   color: #001E62;
    text-align: center;
    font-family: "ASICSFont3.0-Regular";
    font-size: 52.733px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    @media (max-width: 768px) {
      font-size: 32px;
    }
`;
